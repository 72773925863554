@font-face {
    font-family: 'Arial';
    src: url('./fonts/ArialMT.eot');
    src: local('Arial'), local('ArialMT'),
    url('./fonts/ArialMT.eot?#iefix') format('embedded-opentype'),
    url('./fonts/ArialMT.woff2') format('woff2'),
    url('./fonts/ArialMT.woff') format('woff'),
    url('./fonts/ArialMT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Arial';
    src: url('./fonts/Arial-BoldMT.eot');
    src: local('Arial Bold'), local('Arial-BoldMT'),
    url('./fonts/Arial-BoldMT.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Arial-BoldMT.woff2') format('woff2'),
    url('./fonts/Arial-BoldMT.woff') format('woff'),
    url('./fonts/Arial-BoldMT.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Regular'), local('BebasNeueRegular'),
    url('./fonts/bebasneueregular.woff2') format('woff2'),
    url('./fonts/bebasneueregular.woff') format('woff'),
    url('./fonts/bebasneueregular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Bebas Neue';
    src: local('Bebas Neue Bold'), local('BebasNeueBold'),
    url('./fonts/bebasneuebold.woff2') format('woff2'),
    url('./fonts/bebasneuebold.woff') format('woff'),
    url('./fonts/bebasneuebold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
body{
    font-family: 'Arial', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color:white;
    background: #0E0E0E;
}
nav ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    padding: 39px 0 20px;
    border-left: 0.3px solid #FFFFFF4d;
    border-right: 0.3px solid #FFFFFF4d;
    justify-content: space-between;
}
nav li {
    margin: 0 15px;
}
nav a {
    font-family: 'Bebas Neue',sans-serif;
    font-size: 23px;
    line-height: 28px;
    color: #FFFFFF!important;
    text-transform: uppercase;
    padding: 15px 10px;
    display: block;
}
nav a:hover {
    color: #cc0000!important;
    text-decoration: none;
    position: relative;
}

nav a:hover:after {
    content: '';
    top: 100%;
    left: 10px;
    right: 10px;
    height: 1px;
    background: #cc0000;
    position: absolute;
}
ul.sub-menu {
    border: none;
    position: absolute;
    display: none;
    padding: 118px 15px 39px;
    top: 0;
    background: #232323;
    left: 15px;
    z-index: -1;
}

nav>ul>li:hover ul.sub-menu {
    display: block;
}

ul.sub-menu li {
    margin-bottom: 19px;
}

nav ul.sub-menu a {
    line-height: 34px;
    border-bottom: 0.7px solid #FFFFFFb3;
    padding-left: 0;
    padding-right: 0;
}

nav ul.sub-menu a:hover:after {
    display: none;
}

nav ul.sub-menu a:hover {
    border-color: #cc0000;
}

nav a,nav li {
    z-index: 2;
}
header {
    position: relative;
    z-index: 10;
    border-bottom: 0.3px solid #FFFFFF4d;
}

img.logo-img {
    max-width: 100%;
}

a.logo-link {
    display: inline-block;
    padding-top: 8px;
}
.header-phone {padding-top: 54px;text-align: right;}

a.header-phone__link {
    font-family: 'Bebas Neue',sans-serif;
    font-weight: bold;
    font-size: 23px;
    line-height: 23px;
    color: #CC0000;
}

span.header-phone__callback {
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin-top: 4px;
    display: inline-block;
    cursor: pointer;
}
nav>ul>li>a.current {
    color: #cc0000!important;
    position: relative;
}

nav>ul>li>a.current:after {
    content: '';
    top: 100%;
    left: 10px;
    right: 10px;
    height: 1px;
    background: #cc0000;
    position: absolute;
}

nav ul.sub-menu a.current {
    color: #cc0000!important;
    border-color: #cc0000;
}
@media (max-width: 1199px) {
    nav a {
        line-height: 23px;
        padding: 12px 10px;
    }

    nav ul {
        padding: 0;
        border: none;
    }

    .top-menu {
        order: 1;
    }

    img.logo-img {
        height: 60px;
    }

    a.logo-link {
        margin-bottom: 5px;
        padding-top: 5px;
    }

    .header-phone {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
    }
    ul.sub-menu {
        padding-top: 47px;
    }
}
@media (max-width: 991px){
    nav a {
        font-size: 20px;
    }
}
@media (max-width: 767px){
    a.logo-link {
        margin-bottom: 15px;
        padding-top: 15px;
    }
    .burger{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    button.mobile-menu {
        border-style: solid;
        border-width: 1px;
        border-color: white transparent;
        background: none;
        font-size: 0;
        line-height: 0;
        padding: 0;
        width: 35px;
        height: 24px;
        display: block;
        outline: none;
        position: relative;
        transition: height 0.3s;
    }

    button.mobile-menu:before,
    button.mobile-menu:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        background: white;
        transition: 0.3s;
    }
    button.mobile-menu.active {
        border-width: 0;
    }

    button.mobile-menu.active:before {
        transform: rotate(45deg);
    }

    button.mobile-menu.active:after {
        transform: rotate(-45deg);
    }
    nav a {
        display: inline-block;
    }
    .top-menu.active {
        display: block!important;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 270px;
        background: linear-gradient(351.6deg, rgba(255, 255, 255, 0.47) -51.15%, rgba(255, 255, 255, 0) 88.06%), #151321;
        box-shadow: 0 0 15px rgba(0,0,0,0.2);
        z-index: 1;
    }

    nav ul {
        padding: 15px 0;
        flex-direction: column;
    }
    ul.sub-menu {
        position: static;
        padding: 0;
        background: transparent;
        display: block;
        margin-left: 30px;
    }

    ul.sub-menu a {
        font-size: 14px;
        line-height: 18px!important;
        border: none!important;
        padding: 5px 0;
    }

    ul.sub-menu li {
        margin: 0 0 10px;
    }
    .top-menu.active {
        overflow: auto;
    }
    nav li {
        margin: 0;
    }
}
@media (max-width: 575px){
    .header-phone {
        align-items: center;
        padding-bottom: 15px;
    }
}
/*default*/
.default-button {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    display: inline-block;
    border: 1.5px solid;
    padding: 0.66em 1.33em;
    cursor: pointer;
}
.default-button {
    user-select: none;
}

.default-button--white {
    border-color: white;
    color: white;
}

.default-button--white:hover {
    background: white;
    color: black;
}
.default-button--white.default-button--hovered {
    background: white;
    color: black;
}
.default-page a {
    color: #cc0000;
    text-decoration: none;
}

.default-page a:hover {
    color: #cc0000;
    text-decoration: underline;
}
@media (min-width: 1200px){
    .container.my-ml-0 {
        margin-left: 0;
        max-width: calc(1140px + 100vw/2 - 1140px/2);
    }
    .container.my-ml-0>.row {
        margin-left: -30px;
    }
}
@media (min-width:1200px){
    .container.my-mr-0 {
        max-width: calc(1140px + 100vw/2 - 1140px/2);
        margin-right: 0;
    }
}
@media (max-width: 1199px){
    .default-button {
        line-height: 26px;
    }
}
@media (max-width: 991px){
    .default-button {
        font-size: 14px;
        line-height: 26px;
    }
}
@media (max-width: 575px){
    .default-button {
        padding: 0.5em 0.9em;
        font-size: 13px;
    }
}
/*default*/
/*breadcrumbs*/
ul.breadcrumbs {
    margin: 25px 0 0 0;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    list-style-type: none;
    color: #D4CDCD;
    display: flex;
    flex-wrap: wrap;
}

ul.breadcrumbs a {
    color: #a9a9a9;
}

ul.breadcrumbs li:not(:last-child):after {
    content: '|';
    margin: 0 10px;
    color: #a9a9a9;
}
@media (max-width: 1199px){
    ul.breadcrumbs {
        margin: 0 0 25px 0;
    }
}
/*breadcrumbs*/